#servicesCarousel {
    --f-carousel-slide-width: calc(94% / 4);
    --f-carousel-spacing: 0;
  
    --f-button-next-pos: 30px;
    --f-button-prev-pos: 30px;
  
    --f-button-width: 38px;
    --f-button-height: 38px;
    --f-button-border-radius: 50%;
  
    --f-button-color: #fff;
    --f-button-hover-color: #fff;
    --f-button-active-color: #fff;
  
    --f-button-bg: rgba(0, 0, 0, 0.5);
    --f-button-hover-bg: rgba(0, 0, 0, 0.8);
    --f-button-active-bg: rgba(0, 0, 0, 0.8);
  
    --f-button-svg-width: 22px;
    --f-button-svg-height: 22px;
    --f-button-svg-stroke-width: 3;
  }

  #serviceCarousel  .f-carousel__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    width: 100%;
    padding: 3% 10px;
  }
.text-receipt-details.uppercase{
    font-weight: 600 !important;
  }
  @font-face {
    font-family: myHeadFont;
    src: url(../../Assets/fonts/raph.ttf);
  }
  
  .headers_f{
    font-family: "myHeadFont", sans-serif;
  }

@media (max-width: 1000px) {
  #servicesCarousel  {
      --f-carousel-slide-width: calc(94% / 2);
      --f-button-next-pos: 10px;
      --f-button-prev-pos: 10px;
      --f-button-width: 30px;
      --f-button-height: 30px;
    }
  }
  @media (max-width: 400px) {
    #servicesCarousel {
      --f-carousel-slide-width: calc(94% / 1);
      --f-button-next-pos: 10px;
      --f-button-prev-pos: 10px;
      --f-button-width: 30px;
      --f-button-height: 30px;
    }
  }
  
.static-datepicker {
  position: relative;
  display: inline-block;
}

.static-datepicker .react-datepicker-wrapper {
  position: static;
}

.static-datepicker .react-datepicker__input-container input {
  pointer-events: none;
  background-color: #fff;
  
}

.static-datepicker .react-datepicker__input-container input:focus {
  pointer-events: auto;
}

/* .time__container-selected{
  background-color: #dec6c6;
} */
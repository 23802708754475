
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&family=Sora:wght@400;600;700&display=swap');

:root {
  --bg-white: #ffffff;
  --btn-bg: #FC2F32;
  --btn-text: #FAFAFB;
  --hero-color: #FBF2E1;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

/* Video Section  */
.video_wrap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.main_vid{
  min-width: 100%;
  max-width: none !important;
  min-height: 100%;
}
.show_overlay{
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #4b0506;
  z-index: 1;
  opacity: 0.65;
}



/* HERO.JSX SECTION */
.hero_section{
  background-color: transparent;
  height: calc(100vh - 80px) ;
  margin-top: 80px ;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  /* border: 6px solid red; */

}
.uniform_width {
  width: 90%;
  margin: 0 auto;
  /* border: 6px solid rgb(106, 164, 44); */

}
.img{
  width: 50%;
  position: absolute;
  right: 50px;
  bottom: 0;
}
.part_1{
  width: 50%;
  z-index: 10;
}
.part1_h3{
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: 22.56px;
}
.part1_h1{
  font-size: 51.84px;
  font-weight: 700;
  line-height: 70px;
  color: #fff;
  /* border: 1px solid #4b0506; */
  text-align:left;
}
.part1_p{
  font-size: 19.29px;
  line-height: 20px;
  color: #fff;
  width: 100%;
  text-align: start;
  /* border: 1px solid #4b0506; */
}
.part1_span{
  width: 10%;
  height: 2px;
  background-color: #fff;
}

.hero_btn_container{
  display: flex;
  gap: 20px;
  font-weight: 400;
}
.readmore_btn{
  background-color: var(--btn-bg);
  padding: 0.4em 1em;
  border-radius: 5px;
  color: var(--btn-text);
  cursor: pointer;
  list-style-type: none;
}
.about_btn{
  background-color: var(--bg-white);
  padding: 0.4em 1em;
  border-radius: 5px;
  color: var(--btn-bg);
  border: 1px solid var(--btn-bg);
  cursor: pointer;
  list-style-type: none;
}

/* RESPONSIVENESS FOR HOMEPAGEHERO SECTION */
@media screen and (max-width: 850px) {
  .uniform_width {
    width: 95%;
  }
  .part_1 {
    width: 90%;

  }
  .part1_h1{
    font-size: 2rem;
    line-height: 40px;
  }
  .part1_p{
    line-height: 30px;
  }
  .img {
    width: 70%;
  }
  
}


@media (max-width: 480px) {
  .part1_h1 {
    font-size: 2.1rem;
    line-height: 50px;
    }

    .part1_p{
        text-align: left;
        font-size: 18px;
        
        
      }
    }
    
    @media screen and (min-width: 481px) and (max-width: 500px) {
      .part_1 {
        width: 100%;
        
      }
      .part1_h1{
        font-size: 2.1rem;
        line-height: 50px;
        /* border: 1px solid #fff; */
      }
      .img {
        display: none;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .part1_p{
        text-align: left;
        font-size: 24px;
        line-height: normal;
        

    }
   
    .part1_h1{
      font-size: 2.1rem;
      line-height: 50px;
      /* border: 1px solid #fff; */
    }
  }
  @media (min-width: 1023px) {

    .part1_h1{
      font-size: 2.1rem;
      line-height: 50px;
      margin:1rem 0;
    }
    .part1_p{
    margin-bottom: 1rem;
      

  }
  }
  
  @media screen and (min-width: 769px) and (max-height: 1200px)  {
    .part1_h1{
      font-size: 2rem;
      line-height: 40px;
      margin-bottom: 1rem;

    }
    .part1_h3{
      margin-bottom:1.5rem ;
    }
    .part1_p{
      line-height: 40px;
      margin-bottom: 1.1rem;
        
  
    }
  }
  
  
  
  .highlight {
    /* Add your desired highlight or glow effect styles */
    box-shadow: 0 0 10px 5px yellow;
  }
.navbar__container {
background: rgba(255, 255, 255, 0.78);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(7.6px);
-webkit-backdrop-filter: blur(6.5px);
border: 1px solid rgba(255, 255, 255, 0.51);
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; 

  position: sticky;
  z-index: 1000;
  top: 0;
  padding-right: 1rem;
}

.navbar-logo {
  color: #1e0a0a;
  justify-self: start;
  // margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  height: 100%;
  padding: 5px 0px 0px 3rem;
}
.navbar-logo img{
  width: 100%;
}
.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 77vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 1rem;
  justify-content: flex-start;
  font-weight: 400;
}
.nav__active{
  background-color: #FC2F32;
  border-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #fff;
}

.nav-links {

  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.nav-links:active{
    background-color: #FC2F32;
    border-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: #fff;
}
.nav-links:hover {
  background-color: #dedede;
  border-radius: 4px;
  color: #FC2F32;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
@media screen and (max-width: 600px) {
  .navbar__container{
    background: #fff;
    box-shadow:none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border: 1px solid rgba(255, 255, 255, 0.51);
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: calc(100vh - 80px);
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-left: 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .nav-menu.active {
 
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-top: 2rem;
    justify-content: flex-start;
    width: 70%;
  }

  .nav-links {
    text-align: center;
    // width: 70%;
    display: flex;
    display: flex;
    align-items: center;
    gap:1rem;
  background-color: #fff;

    
  }

  .nav-links:hover {
    background-color: #FC2F32;
    border-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: #fff;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 8px 18px;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
}}
@media screen and (min-width: 601px) and (max-width: 960px)  {
  .navbar__container{
    background: #fff;
    box-shadow:none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border: 1px solid rgba(255, 255, 255, 0.51);
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: calc(100vh - 80px);
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-left: 1rem;
    background-color: #fff;
    padding-top: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    display: flex;
    padding-top: 2rem;
    justify-content: flex-start;
  }

  .nav-links {
    text-align: center;
    width: 70%;
    display: flex;
    display: flex;
    align-items: center;
    gap:1rem;
    background-color: #fff;

    
  }

  .nav-links:hover {
    background-color: #FC2F32;
    border-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: #fff;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 8px 18px;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

}

@media screen and (max-width: 960px) and (min-height:700px) {

  .nav-menu{
    justify-content: flex-start;
    width: 40%;
    
  }
  .nav-item{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100px;
    font-size: 1.1rem;

  }


}
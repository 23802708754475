:root {
    --bg: #f3f5f9;
    --white: #ffffff;
    --dark: #333333;
    --grey: #999999;
    --priClr: #fc2f32;
    --grey2: rgba(255, 255, 255, 0.7);
  }
.home_flex-container {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    height: fit-content;
  }
  .about-light {
    background-color: var(--white);
  }
  .home_about-grey {
    color: #cccccc;
  }
  .home_about-width {
    /* width: min(80%, 1300px); */
    width: 100%;
    margin-inline: auto;
  }
  .custom-margin {
    /* margin-bottom: 8rem; */
  }
  .flex-title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
  
  .home_flex-sub-title {
    font-size: 2.6rem;
    color: #fff;
    font-weight: 700;
  }
  
  .home_flex-sub {
    line-height: 24px;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
  
  .home_about-btn {
    all: unset;
    background-color: var(--priClr);
    padding: 0.7em 2.5em;
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--white);
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .home_about-btn:hover {
    transform: scale(1.05);
   
  }
  .home_flex-content {
    width: 50%;
    padding: 3.6em;
    background: #000;
    height: 520px;
  }
  .home_flex-img{
    width: 50%;
    height: 520px;
  }
  .home_flex-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .reverse-column {
    flex-direction: row-reverse;
  }
  @media (max-width: 1260px) {
    .about-width {
      width: 100%;
    }
    .home_flex-content {
      width: 50%;
    }
  }

  @media (max-width: 1060px) {
    .home_about-width {
      width: 100%;
    }
    .custom-margin {
      /* margin-bottom: 5rem; */
    }
    .hero-img {
      width: 100%;
    }
    .about-section-container {
      padding: 6em 0.6em 2.7em;
    }  
    .about-grid-layout {
      grid-template-areas:
        "one"
        "two"
        "three";
    }
    .home_flex-container {
      width: 100%;
    }
    .home_flex-content {
      width: 100%;
      padding: 2rem;
    }
    .home_flex-sub-title {
      line-height: 55px;
    }
    .home_flex-sub {
      line-height: 30px;
      font-size: 0.9rem;
    }
    .home_flex-img{
        width: 100%;
      }
  }
  @media (max-width: 900px){
    .home_flex-container {
        flex-direction: column;
        align-items: center;
      }
      .home_flex-content{
        height: fit-content;
      }
  }
$colors: (
  lightRed: #fc2f32
);
body {
  background-color: transparent !important;
}
.makeup_sec {
  padding: 50px 150px 0px 150px;
  display: grid;
  gap: 50px;
}
.image__container{
  width: 300px;
  border-radius: 50% !important;
  height: 300px;
  margin:  0 auto ;
  display: flex;
  justify-content: center;
  align-items: center;
  // align-self: center;
// border: 5px solid rgb(255, 0, 0);
background-color: #fc2f32;

}
.makeup__img{
  width: 160px;
  border-radius: 50% !important;
  height: 160px;
  margin:  0 auto ;
  display: flex;
  align-self: center;
// height:auto;
  // border-radius: 6px !important;
// width: 250px;
// border: 1px solid rgb(255, 0, 0);

}
.makeup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 100px;
  padding: 20px;
  box-shadow: 0px 11px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  div {
    display: flex;
    gap: 20px;
    align-items: center;

    img {
      border-radius: 50px ;
    }
  }


  #select_btn {
    background-color: map-get($colors, lightRed);
    color: white;
    padding: 0.2rem 1rem;
    border-radius: 20px;
  }
}

.makeup_children_sec {
  display: grid;
  gap: 50px;
  padding: 10px 50px 50px 50px;

  .makeup_children {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 11px 24px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: 200px;
    gap: 20px;

    h1 {
      font-weight: bold;
    }
  }

  .makeup_intro_sec {
    display: flex;
    gap: 20px;
    align-items: center;

    .makeup_content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .book_now {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    #book_now_btn {
      background: map-get($colors, lightRed);
      color: white;
      border-radius: 10px;
      width: 100px;
    }
  }
}



#back_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: map-get($colors, lightRed);
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
}

@media (max-width: 999px) {
  .makeup_sec {
    padding: 20px;
  }

  #select_btn {
    display: block;
  }
}

@media (max-width: 1184px) {
  .makeup_children_sec {
    // grid-template-columns: repeat(1, 1fr);
    padding: 20px;
    // align-items: center;
  }

  .makeup_children {
    // flex-direction: column;
  }
}

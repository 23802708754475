@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,200;1,400;1,500&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ecf0f1;
  /* border: 1px solid blue; */
}

.container {

  text-align: center;
  margin: auto;
  padding: 3em;
}
.container img {
  width: 300px;
  height: 300px;
  margin: auto;
}
.container h1 {
 
  font-size: 30px;
  text-align: center;
}
.container h1 span {
  font-size: 50px;
}


.container p.info a {
  text-decoration: underline;
  color: #5454ce;
}
.img__postion{
    text-align: center;
}
.home__text{
  font-size: large;
  
}


.gallery__container {
  max-width: 80rem;
  width: 100%;
  padding: 4rem 2rem;
  margin: 0 auto;
}

.filter__window li.selected {
  color: #000;
  background-color: #5B5754;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

}
.main__gallery_cont .gallery__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
}

.main__gallery_cont .gallery__card {
  color: #252a32;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
}

.main__gallery_cont .gallery__card-image {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 70%;
  background: #ffffff;
}

.main__gallery_cont .gallery__card-image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media only screen and (max-width: 982px) {
  .main__gallery_cont .gallery__container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 0.8rem;
  }
}
@media only screen and (max-width: 768px) {
  .main__gallery_cont .gallery__container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 0.9rem;
  }
}
@media only screen and (max-width: 600px) {
  .main__gallery_cont .gallery__container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .main__gallery_cont .gallery__container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1.2rem;
  }
}
.loading_container{
    padding: 0;
    margin: 0;
    background-color: #fff;
    text-align: center;
    height:100vh;
    font-family: 'poppins';
    /* font-weight: 100; */
  }
  .wrapper{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%); 
  }
  .circle{
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #fcdc29;
    border-radius: 50%;
    animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
  }
  @keyframes loading{
    0%{
      transform: translateY(0px);
      background-color: #fcdc29;
    }
    50%{
      transform: translateY(50px);
      background-color: #ef584a;
    }
    100%{
      transform: translateY(0px);
      background-color: #fcdc29;
    }
  }
  .circle_1{
    animation-delay: 0.1s;
  }
  .circle_2{
    animation-delay: 0.2s;
  }
  .circle_3{
    animation-delay: 0.3s;
  }
  .circle_4{
    animation-delay: 0.4s;
  }
  .circle_5{
    animation-delay: 0.5s;
  }
  .circle_6{
    animation-delay: 0.6s;
  }
  .circle_7{
    animation-delay: 0.7s;
  }
  .circle_8{
    animation-delay: 0.8s;
  }
  
  
  










  .center-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    /****** center box
    width: 300px;
    height: 300px;
    border: solid 1px #aaa;
    ******/
  }

  .loader-circle-9 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    font-family: sans-serif;
    font-size: 12px;
    color: #00eaff;
    text-transform: uppercase;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
  }
  .loader-circle-9:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #00eaff;
    border-right: 3px solid #00eaff;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
  }
  .loader-circle-9 span {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animate 2s linear infinite;
  }
  .loader-circle-9 span:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #00eaff;
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px #00eaff;
  }
  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
  
  
  
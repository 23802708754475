
  
  .filter__window {
    width: 97% !important;
    height: auto;
    margin: 0 auto;
 
    overflow: hidden;

    flex-direction: column;
  }
  
  .filter__window .filter__nav {
    /* background: #F2F5F9; */
    padding: 2px 0 0;
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 100vh;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;

  }
  

  .filter__nav::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background:  #5B5754;

    -webkit-border-radius: 2px;
  }
  
  .filter__nav::-webkit-scrollbar-thumb {
    background: #fff;

    -webkit-border-radius: 2px;
  }
  
  .filter__nav::-webkit-scrollbar-corner {
    background:  #fff;

  }
  .filter__window .tabs {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;
    width: 100%;
  }
  
 .filter__window .filter__tabs .filter__main {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 128px;
    flex-grow: 1;
    user-select: none;
  }
  
  .filter__window .ul__wrapper,
  .li__wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
  }
  
  .filter__window .ul__wrapper {
    display: flex;
    width: 100%;
  }
  
  .filter__window .li__wrapper {

    width: 100%;
    padding: 0 15px;
    position: relative;
    /* background: #F2F5F9; */
    color: #fff;
    cursor: pointer;
    /* height: 24px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 0;
    position: relative;
    user-select: none;
  }
  
  .filter__window .underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: #FD4447;
  }
  
  .filter__window li.selected {
    background: #eee;
  }
  
  .filter__window li button {
    width: 20px;
    height: 20px;
    border: 0;
    background: #fff;
    /* border-radius: 3px; */
    display: flex;
    justify-content: center;
    align-items: center;
    stroke: #000;
    margin-left: 10px;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .filter__window .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #fff;
  }
  
  .filter__window .add-item {
    width: 30px;
    height: 30px;
    background: #eee;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    align-self: center;
  }
  
  .filter__window .add-item:disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
  /* @media only screen and (max-width: 768px) { */
  @media only screen and (max-width:960px ) {
    .filter__window .li__wrapper {
      width: 98px;
      padding: 0 74px;

    }
    .filter__window .ul__wrapper {
      /* width:auto; */
    }
  }
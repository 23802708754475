$red: #C3141F;
$white: #F7F7F7;
$black: #080808;


   a {
      color:inherit;
   }
   .overlay {
      z-index:100000000000;
      position: fixed;
      top:0px;
      left:0px;
      bottom:0px;
      right:0px;
      background: linear-gradient(rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 15%, rgba(0,0,0,0) 85%, rgba(0,0,0,0.1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#1a000000',GradientType=0 );
      box-shadow:inset 0 0 250px 0 rgba(0,0,0,0.2);
      pointer-events: none;
   }
   .sidebar {
      position: relative;
      min-height:100vh;
      background-color:$white;
      color:$red;
      min-width:250px;
      max-width:250px;
      .sidebarcontent {
         position: fixed;
         top:0px;
         left:0px;
         bottom:0px;
         width:250px;
         .title {
            font-family: 'Oswald', sans-serif;
            font-weight:bold;
            font-size:72px;
            width:100%;
            text-align:center;
            letter-spacing:-2px;
            a {
               text-decoration:none;
            }
         }
         brkr {
            display:block;
            width:calc(100% - 40px);
            height:1px;
            margin:auto;
            background-color:$red;
         }
         nav {
            padding:16px 0px;
            &:hover a li {
               //opacity:0.5;
            }
            a {
               text-decoration:none;
               li {
                  width:100%;
                  text-align:center;
                  list-style:none;
                  line-height:24px;
                  font-size:22px;
                  letter-spacing:2px;
                  &:hover {
                     background-color:$red;
                     box-shadow:0 1px 0 1px $red;
                     color:$white;
                     text-decoration:none;
                  }
               }
            }
         }
         p {
            width:100%;
            text-align:center;
         }
         .details {
            .title {
               width:calc(100% - 40px);
               margin:auto;
               margin-top:4px;
               text-align:left;
               font-size:30px;
               letter-spacing:0px;
               line-height:34px;
               &.view, &.view a, &.download, &.download a {
                  text-align:center;
                  font-size:30px;
                  width:100%;
                  cursor: pointer;
                  padding-bottom:3px;
                  text-decoration:none;
               }
            }
            .description {
               width:calc(100% - 40px);
               margin:auto;
               margin-bottom:4px;
            }
            &.file-details {
               margin-top:2px;
               font-size:10px;
            }
         }
      }
   }
   .content {
      position: relative;
      min-height:calc(100vh - 24px);
      background-color:$red;
      color:$white;
      width:100%;
      padding:12px 22px;
      .post {
         //margin-top:40px;
         //margin-bottom:40px;
         //border-top:1px solid #fff;
         //padding-top:10px;
         &:first-child {
            //padding-top:0px;
            //border-top:none;
            //margin-top:0;
         }
         .title {
            font-family: 'Oswald', sans-serif;
            font-size:72px;
            line-height:84px;
            padding-bottom:10px;
         }
         .text {
            font-size:24px;
            a:hover {
               background-color:$white;
               box-shadow:0 2px 0 2px $white;
               color:$red;
               text-decoration:none;
            }
         }
      }
      brkr {
            display:block;
            width:100%;
            height:1px;
            margin:auto;
            background-color:$white;
         }
      .imagecontainer {
         position: absolute;
         top:22px;
         left:22px;
         bottom:22px;
         right:22px;
         text-align: center;
         line-height: 100%;
         img {
            max-width:100%;
            max-height:100%;
            vertical-align: middle;
         }
         &.padding img {
            padding:4px;
         }
         &.small_padding img {
            padding:1px;
         }
         &.white img {
            background-color:$white;
         }
      }
      .textcontainer {
         user-select:text;
         background-color:$white;
         color:$black;
         max-width: calc(100vw - 331px);
         margin: 10px -18px;
         cursor: text;
         white-space: pre;
         padding:-100px 0;
         word-wrap: break-word;
         padding:10px;
         font-family: 'Inconsolata', 'Consolas', Monospaced;
         .hljs {
            .hljs-string {
               color:#A31515;
            }
            .hljs-keyword {
               color: #569CD6;
            }
            .hljs-number {
               color: #7F9076;
            }
            .hljs-comment {
               color: #AAAAAA;
            }
            .hljs-title {
               color: #2B91AF;
            }
         }
      }
      .loader {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: 50px;
         height: 50px;
         animation: rotate steps(4) 2s infinite;
         &:before {
            display:block;
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 25px;
            height: 25px;
            background-color: $white;
            animation: blockmove 0.5s infinite;
         }
         &:after {
            display:block;
            content: '';
            position: absolute;
            top: 0px;
            left: 25px;
            width: 25px;
            height: 50px;
            background-color: $white;
         }
      }
      &.flex-stack {
         display:flex;
         flex-direction:column;
         flex-flow: space-around;
         .post {
            padding:20px 0;
            &:first-child {
               padding-top:0px;
            }
         }
      }
      .noentry {
         position: absolute;
         top:50%;
         left:50%;
         transform:translate(-50%,-50%);
         width:60vw;
         height:60vw;
         min-width:400px;
         min-height:400px;
         border-radius:100%;
         background-color:$white;
         .ban {
            position: absolute;
            content: '';
            top:50%;
            left:50%;
            transform:translate(-50%,-50%) rotate(45deg);
            width:16.6667%;
            height:120%;
            background-color:$red;
         }
         .messagebox {
            background-color:#191718;
            min-width:250px;
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            border-radius:4px;
            padding:20px;
            display:flex;
            flex-direction: column;
            justify-content: center;
            >* {
               margin:10px 0;
               text-align:center;
            }
            .buttoncontainer {
               display:flex;
               flex-direction:row;
               justify-content:center;
               .button {
                  border-radius:100px;
                  padding:5px 20px;
                  padding-bottom:8px;
                  border: 2px solid $red;
                  cursor: pointer;
               }
            }
         }
      }
   }


@keyframes blockmove {
   0% {
      top:0px;
   }
   100% {
      top:25px;
   }
}
@keyframes rotate {
   0% {
      transform:translate(-50%,-50%) rotate(0deg);
   }
   100% {
      transform:translate(-50%,-50%) rotate(360deg);
   }
}
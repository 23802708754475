
.img_div {
    /* width: 30%; */
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.image{
    width: "30%"
}

/* @media screen and (max-width: "800px") {
   .img_div{
    width: "100%";
   } 
} */
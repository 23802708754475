.f-carousel__slide {
    padding: 50px;
    text-align: center;
 

  }

  .f-carousel-img__container{
    width: 100%;
    height: calc(100vh - 80px);
    /* margin-top: 80px; */
    background-blend-mode: overlay;
    /* border: 3px solid blue; */
  }

  .f-carousel-img__container img, video {
    max-width: 100%;
    height: 100% !important;
}
.f-carousel__dots{
    display: none !important;
}
/* .fancybox__container{
    background-color: #000 ;
} */
.carousel-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* padding: 10px; */
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 14px;
  }

.f-carousel-img__container {
    position: relative;
  }
  
  .image{
    width: 100%;
    height: 100%;
    /* background-image: url("https://images.pexels.com/photos/5622271/pexels-photo-5622271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"); */
    background-position: center;
    object-fit: cover;
  }



  /* ------------------------------------- */

  .button__view-all {
    display: inline-block;
    border-radius: 4px;
    background-color: #FE0222;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    padding:  8px 24px;
    /* width: 200px; */
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
  }
  
  .button__view-all span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .button__view-all span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .button__view-all:hover span {
    padding-right: 25px;
  }
  
  .button__view-all:hover span:after {
    opacity: 1;
    right: 0;
  }
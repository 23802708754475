@font-face {
    font-family: myHeadFont;
    src: url(../../Assets/fonts/raph.ttf);
  }

  .beforeAfter__font-title{
    font-family: "myHeadFont", sans-serif;
  }
.before_con{
  border-radius: 10px ;

}

  @media (max-width: 900px){
    .beforeAfter__font-title{
      margin-top: 1rem;
      
    }
    .before_con{
  border-bottom-right-radius: 10px;
      
    }
  }
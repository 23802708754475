.dropdown-menu {
  width: 250px;
  position: absolute;
  top: 77px;
  list-style: none;
  text-align: start;
  padding: 0 1rem ;
  background-color: #ffffff;
  border-bottom-left-radius: 6px ;

  border-bottom-right-radius: 6px ;
  /* z-index: 10; */
}
.dropdown-menu{

  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;

}

.dropdown-menu .dropdown__list {
  background: #ffffff;
  cursor: pointer;
  color: #000;
  padding: 8px 16px;
  border-radius: 6px;
  /* margin-bottom: 1rem; */
}
.dropdown-menu .dropdown__list:first-child{
  margin-top: 1rem;
}
.dropdown-menu .dropdown__list:last-child{
  margin-bottom: 1rem;
}

.dropdown-menu .dropdown__list:hover {
  background: #FC2F32;
  color: #fff;
}
.dropdown-menu .dropdown__list:hover .dropdown-link {
  /* border-radius: 6px; */
  background: #FC2F32;
  color: #fff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000;
  background-color: #fff;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

.loginContainer {
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  flex-flow: row wrap;
  padding-top: 2rem;
  padding: 2rem 1.5rem;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin:  auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.googleAuth{
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .loginContainer {
    width: 70%;
  }
}

@media (min-width: 600px) {
  .loginContainer {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .loginContainer {
    width: 45.33%;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&family=Sora:wght@400;600;700&display=swap');
@font-face {
  font-family: 'Paula Natalie', sans-serif;
                                                
  src: url(../../Assets/fonts/raph.ttf);
}

:root {
  --bg-white: #ffffff;
  --btn-bg: #FC2F32;
  --btn-text: #FAFAFB;
  --hero-color: #FBF2E1;
}


.home_more__gallery {
  all: unset;
  background-color: #fc2f32;
  padding: 0.7em 2.5em;
  font-weight: 600;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
}
.home_more__gallery:hover {
  transform: scale(1.05);
  transition: 0.3s ease;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}
.headers_f{
  font-family: "Paula Natalie", sans-serif;
  font-size: 2rem;

}
.testimonial_width{
  font-size: 3rem;
  font-family: "myHeadFont", sans-serif;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.b_and_a_txt{
  /* padding: 1rem 5rem;
  text-align: justify;
  color: #eee; */
  color: #cccccc;
  line-height: 30px;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}
.b_and_a_head{
  color: #fff;
  font-size: 2.9rem;
  font-weight: 700;
  margin-top: 2.5rem;
}
/* Video Section  */
.video_wrap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.main_vid{
  min-width: 100%;
  max-width: none !important;
  min-height: 100%;
}
.show_overlay{
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #4b0506;
  z-index: 1;
  opacity: 0.65;
}



/* HERO.JSX SECTION */
.hero_section{
  background-color: transparent;
  height: calc(100vh - 80px) ;
  margin-top: 80px ;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}
.uniform_width {
  width: 90%;
  margin: 0 auto;
}
.img{
  width: 50%;
  position: absolute;
  right: 50px;
  bottom: 0;
}
.part_1{
  width: 50%;
  z-index: 10;
}
.part1_h3{
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
}
.part1_h1{
  font-size: 4rem;
  font-weight: 700;
  line-height: 70px;
  color: #fff;
}
.part1_p{
  font-size: .85rem;
  line-height: 20px;
  color: #fff;
}
.part1_span{
  width: 10%;
  height: 2px;
  background-color: #fff;
}

.hero_btn_container{
  display: flex;
  gap: 20px;
  font-weight: 400;
}
.readmore_btn{
  background-color: var(--btn-bg);
  padding: 0.4em 1em;
  border-radius: 5px;
  color: var(--btn-text);
  cursor: pointer;
  list-style-type: none;
}
.about_btn{
  background-color: var(--bg-white);
  padding: 0.4em 1em;
  border-radius: 5px;
  color: var(--btn-bg);
  border: 1px solid var(--btn-bg);
  cursor: pointer;
  list-style-type: none;
}

/* scroll to top */

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* width: 40px;
  height: 40px; */
  background-color: #fff;
  /* background-color: #eeeaea; */
  color: #fff;
  border: none;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-to-top.visible {
  opacity: 1;
}

@media (max-width: 1060px) {

  .b_and_a_txt{
    line-height: 30px;
    font-size: 0.9rem;
  }
}

/* <StyledFab
color="primary"
aria-label="Scroll"
onClick={scrollToTop}
// style={{ opacity: isVisible ? 0 : 1 }}
>
<FaArrowAltCircleUp />
</StyledFab> */












/* RESPONSIVENESS FOR HOMEPAGEHERO SECTION */
@media screen and (max-width: 850px) {
  .uniform_width {
    width: 95%;
  }
  .part_1 {
    width: 70%;
  }
  .img {
    width: 70%;
  }
  
}
@media screen and (max-width: 500px) {
  .part_1 {
    width: 100%;
  }
  .img {
    display: none;
  }
  .part1_h1{
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 60px;
  }
  .b_and_a_txt{
    padding: 0.5rem 1.5rem;
  }
  .b_and_a_head{
    font-size: 2.5rem;
    padding: 1rem;
  }
  
}

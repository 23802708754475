$bg-color: #131b20;
$colors: (
  primary: #f0f0f0,
  white: #ffffff
);

.footer {
  padding: 50px;
  background-color: $bg-color;
  font-family: 'Poppins';
  color: map-get($colors, primary);
  font-size: 0.9rem;
}

.footer_links_sec {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding-bottom: 70px;
}

.footer_link_sec1 {
  width: 28%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    text-align: justify;
  }
}
.logo {
  display: flex;
  gap: 20px;
  align-items: center;

  h1 {
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
  }
}

.flex_item {
    display: flex;
    gap: 30px;
}

.footer_link_sec2 ul,
.footer_link_sec3 ul,
.footer_link_sec4 ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer_link_sec4 {
  width: 40%;
  display: flex;
  gap: 10px;
}

.footer_link_sec4 li {
  display: flex;
  gap: 10px;
}

#head {
    font-size: 1.1rem;
    font-weight: 700;
    color: map-get($colors, white);
  }  

.footer_links_sec2 {
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  align-items: center;
}

.footer_link_copyrite {
  display: flex;
  gap: 50px;
}

.flex_item2 {
    display: flex;
    gap: 50px;
}

.footer_media_icon ul {
  display: flex;
  gap: 20px;

  li {
    border: 1px solid map-get($colors, white);
    border-radius: 50px;
    padding: 5px;
  }
}


@media (max-width: 1500px){
  .footer_link_sec4 {
    width: 50%;
  }
}
@media (max-width: 1191px){
  .footer_link_sec4 {
    width: 69%;
  }
}
@media (max-width: 966px){
    .footer {
        padding: 20px;
    }

    .footer_links_sec {
        flex-direction: column;
        gap: 30px;
    }
    .footer_link_sec1 {
        width: 100%;
        justify-content: center;
        align-items: center;

        p {
            font-size: 1rem;
            text-align: justify;
        }
    }
    // .flex_item {
    //     justify-content: center;
    // }

    .footer_link_sec4 {
        width: 83%;
        font-size: 1rem;
    }

    .footer_links_sec2 {
        flex-direction: column-reverse;
        padding-top: 20px;
        gap: 20px;
        justify-content: center;
        align-items: center;

        .footer_link_copyrite {
            flex-direction: column-reverse;
            gap: 20px;
            justify-content: center;
            

            p{
                font-size: 1rem;
                text-align: center;
            }

            a {
                font-size: 1rem;
            }
        }

        .logo {
            gap: 10px;
        
            h1 {
              font-size: 1.5rem;
            }
          }
    }
}
@media (max-width: 641px) {
  .footer_link_sec4 {
    width: 100%;
    font-size: 0.9rem;
}
}
@media (max-width: 500px) {
  .footer_link_sec4 {
    width: 100%;
    font-size: 0.9rem;
    flex-direction: column;
    gap: 30px;
}
}
.qr_before_con{
    display: flex;
    /* max-width: 1100px; */
    /* width: 80%; */
    /* margin: 0 auto; */
    padding: 50px 0px;
    cursor: pointer;      
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 905px) {
    .qr_before_con{
        flex-direction: column;
        gap: 50px;
        width: 100%;
    }
}
.receipt-container::before {
  content: "";
  background-image: url("../../../src/Assets/Images/Icons/danlogo.svg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}

.c-section{
    width: 100%;
    height: 500px;
    background-color: #fc2f32;
    display: flex;
    flex-wrap: wrap;
    background-image: url(../../Assets/Images/shapes/part.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode:color-burn;
    overflow: hidden;
}
.c-section .text__wrap{
    flex: 0 0 auto;
    width: 50%;
    padding: 2.8rem;
}
.c-section .text__wrap h2 span{
    font-size: 4rem;
    color: #fff;
    font-weight: 700 !important;
}
.c-section .text__wrap .__service_para{
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 1rem;
}
.c-section .text__wrap .text__below{
    font-size: 0.9rem;
    font-weight: 400;
    color: #dadada;
}
.c-section .img__wrap{
    flex: 0 0 auto;
    min-height: 100%;
    width: 50%;
    overflow: hidden;
    /* max-height: 450px; */
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 50%;
    border-left: 10px solid #fff;
    /* background-color: blue; */
    /* margin-right: calc(-50vw + 50%) !important; */
}
/* .c-section .img__wrap::after{
    top: 0;
    width: 100%;
    left: -1px;
    content: "";
    z-index: 1;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-image: url(../../Assets/Images/shapes/left_banner2.png);
    background-blend-mode: color-burn;
} */
/* .c-section .img__wrap img{
    width: 100%;
    height: 100%;
    
    border-bottom-left-radius: 50%;
} */
@media (max-width: 1269px){
    .c-section{
        height: 500px;
    }
}
@media (max-width: 1124px){
    .c-section .text__wrap{
        padding: 10px 2.5rem;
    }
}
@media (max-width: 960px){
    .c-section{
        /* flex-direction: column; */
    }
    .c-section .text__wrap{
        width: 100%;
    }
    .c-section .img__wrap{
        width: 100%;
        height: 400px;
    }
    .c-section .text__wrap h2 span{
        font-size: 3rem;
    }
    /* .c-section .img__wrap img{
        width: 100%;
        border: none;
        border-top-left-radius: 0;
    } */
    .c-section .text__wrap .text__below{
        margin-bottom: 1rem;
    }
}
@media (max-width: 500px){
    .c-section .text__wrap h2 span{
        font-size: 2rem;
    }
    .c-section .text__wrap .__service_para{
        font-size: 0.8rem;
    }
    .c-section .text__wrap .text__below{
        font-size: 0.8rem;
        text-align: justify;
    }
    .c-section .img__wrap{
        height: 40vh;
    }
}